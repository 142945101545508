import React from 'react'
import { makeStyles } from "@material-ui/styles";
import AnimatedLevite from './AnimatedLevite'



const FondoAnimado = (props) => {
   
    const useStyles = makeStyles((theme) => ({
        fondo: {
          // zIndex: "-1",
          color: "#2F2A42",
          width: "100%",
          minHeight: "600px",
          height: props.fullHeight===true?"100vh":"auto",
          position: "relative",
          // backgroundColor: "#2F2A42",
          backgroundImage: `url(${
            props.fondo
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        },
        fondoLetras: {
          zIndex: "-1",
          width: "100%",
          height: "100vh",
          position: "relative",
          // backgroundColor: "#2F2A42",
          backgroundImage: `url(${
            props.animacion
          })`,
          backgroundRepeat: "repeat",
          backgroundPosition: "center",
          // backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }))
      const classes = useStyles();
    return (
        <div className={classes.fondo}>
      <AnimatedLevite>
        <div className={classes.fondoLetras}></div>
      </AnimatedLevite>
        {props.children}
        </div>
    )
}

export default FondoAnimado
