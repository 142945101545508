import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { gsap } from "gsap";

const fondos = [
  {
    fondo: require("../assets/header/Botones/fondo0.png").default,
    color: "#E4323F",
  },
  {
    fondo: require("../assets/header/Botones/fondo1.png").default,
    color: "#0ACAB9",
  },
  {
    fondo: require("../assets/header/Botones/fondo2.png").default,
    color: "#D7B995",
  },
  {
    fondo: require("../assets/header/Botones/fondo3.png").default,
    color: "#E4323F",
  },
  {
    fondo: require("../assets/header/Botones/fondo4.png").default,
    color: "#0ACAB9",
  },
];

const BotonMenu = (props) => {
  const { link, tipo, inclinacion, handleClose, fixedHover, titulo } = props;
  const backgroundRef = useRef();
  const textRef = useRef();

  const useStyles = makeStyles((theme) => ({
    button:
      titulo === false
        ? {
            color: "#ACACCC",
            fontFamily: "AlegreyaSansSC-Light",
            fontSize: 24,
            width:"100%",
            "&:hover": {
              color: {},
            },
          }
        : { color: {}, fontFamily: "AlegreyaSansSC-Light", fontSize: 24,  width:"100%", },
    contenedor: {
      position: "relative",
      width: "100%",
      maxWidth: "340px",
      minWidth: "320px",
      margin: "auto",
      height: "70px",
      maxHeight: "70px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "none",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    cuadroTexto: {
      width: "226px",
      height: "150px",
      backgroundsize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${
        require("../assets/header/Botones/dialogo1.png").default
      })`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    alegreyaSansSCBold: {
      fontFamily: "AlegreyaSansSC-Bold",
      fontSize: "20px",
      color: "#002B49",
      width: "100%",
      height: "28px",
      lineHeight: "27px",
    },
    background: {
      marginTop: -10,
      position: "absolute",
      height: "100%",
      opacity: 0,
      scale: 0.6,
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!fixedHover) {
      fadeOut();
    }
  }, [fixedHover]);

  const click = () => {
    if (link) {
      history.push(link);
      handleClose();
    }
  };
  const fadeIn = () => {
    console.log("fadeIn", fixedHover);

    gsap.to([backgroundRef.current], {
      duration: "0.3",
      scale: 1,
      opacity: 1,
    });
    gsap.to([textRef.current], {
      duration: "0.3",
      fontFamily: "AlegreyaSansSC-Medium",
      color: fondos[tipo].color,
      transform: `rotate(${inclinacion}deg)`,
    });
  };
  const fadeOut = () => {
    console.log("fadeOut", fixedHover);
    if (!fixedHover) {
      gsap.to([backgroundRef.current], {
        duration: "0.3",
        scale: 0.6,
        opacity: 0,
      });
      gsap.to([textRef.current], {
        duration: "0.3",
        color: "#ACACCC",
        transform: `rotate(0deg)`,
        fontFamily: "AlegreyaSansSC-Light",
      });
    }
  };
  return (
    <div
      className={classes.contenedor}
      onMouseEnter={fadeIn}
      onMouseLeave={fadeOut}
    >
      <img
        alt="background"
        src={fondos[tipo].fondo}
        ref={backgroundRef}
        className={classes.background}
      />
      <Button ref={textRef} onClick={click} className={classes.button}>
        {props.children}
      </Button>
    </div>
  );
};

export default BotonMenu;
