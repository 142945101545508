import React, { useState } from "react";
import { Link, makeStyles } from "@material-ui/core";
import Img from "../components/Img";
import corazon from "../assets/header/Botones/corazon.png";
import BotonMenu from "./BotonMenu";
import { Link as RouterLink } from "react-router-dom";

// import { useHistory } from "react-router";

const BotonShop = ({ handleClose }) => {
  const useStyles = makeStyles((theme) => ({
    contenedor: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      width: "150px",
      height: "65px",
      margin: "auto",
    },
    button: {
      position: "relative",
      width: "115px",
      height: "45px",
      transitionProperty: "background-image",
      transitionTimingFunction: "cubic-bezier(0.2, 0.4, 0.7, 0.8)",
      transitionDuration: "1s",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${
        require("../assets/header/Botones/shop.png").default
      })`,
      "&:hover": {
        width: "130px",
        height: "65px",
        backgroundImage: `url(${
          require("../assets/header/Botones/shopOver.png").default
        })`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        transitionProperty: "background-image",
        transitionTimingFunction: "cubic-bezier(0.2, 0.4, 0.7, 0.8)",
        transitionDuration: "1s",
      },
    },
    openText: {
      zIndex: 10,
      position: "absolute",
      width: "52px",
      height: "50px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${
        require("../assets/header/Botones/yes.png").default
      })`,
      right: "-30px",
      top: "-30px",
    },
    link: {
      width: "100%",
      textAlign: "center",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
  }));
  const classes = useStyles();
  const [overBoton, setOverBoton] = useState(false);
  const handleClick = () => {
    handleClose();
  };
  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setOverBoton(!overBoton)}
      onMouseLeave={() => setOverBoton(!overBoton)}
      onClick={handleClick}
    >
      {overBoton && <div className={classes.openText} />}

      <Link className={classes.link} to={"/Our-Wines"} component={RouterLink}>
        <BotonMenu
          handleClose={handleClose}
          link={null}
          tipo={4}
          inclinacion={0}
        >
          Shop <Img src={corazon} />
        </BotonMenu>
      </Link>
    </div>
  );
};

export default BotonShop;
