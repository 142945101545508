import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import BotonMenu from "../components/BotonMenu";
import FondoAnimado from "../components/FondoAnimado";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Collapse } from "@material-ui/core";
import Img from "../components/Img";
import BotonWhatsapp from "../components/BotonWhatsapp";
import BotonShop from "../components/BotonShop";
import manito_izq from "../assets/header/Botones/mano_izq.png";
import manito_der from "../assets/header/Botones/mano_der.png";
import marcianito from "../assets/header/Botones/marcianito.png";
import chupetin from "../assets/header/Botones/chupetin.png";
import copa_vino from "../assets/header/Botones/copa_vino.png";
import { useTranslation } from "react-i18next";

export const NumberContext = React.createContext();

const MenuIcon = () => {
  return (
    <img
      width={50}
      height={50}
      alt={"logo nave ovni"}
      src={require("../assets/header/hamburguesa_ovni.gif").default}
    />
  );
};
const styleDrawer = (theme) => ({
  root: {
    "& div.MuiDrawer-paperAnchorTop": {
      top: 0,
      height: "100vh",
      
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& div.MuiPaper-root": {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transitionDuration: ".5s !important",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        maxWidth: 500,
      },
    },
  },
  // "& .MuiDialogContent-root": {
  //   padding: "0px 0px 30px",
  //   [theme.breakpoints.down(600)]: {
  //     padding: "0px 0px 30px",
  //   }
});
const CustomDrawer = withStyles(styleDrawer)(Drawer);

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
    width: "100%",
    height: 70,
    left: 0,
    right: 0,
    zIndex: 999,
    background: "#0ACAB9",
    padding: "0 10px 0 0",
    [theme.breakpoints.up("md")]: {
      padding: "0 16px",
    },
  },
  toolbar: {
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    padding: 0,
    minHeight: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonClose: {
    height: 50,
    width: 50,
    minWidth: 50,
    padding: 0,
  },
  logoOvni: {
    height: 40,
    width: 76,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url(${
      require("../assets/header/logo_ovni_appbar.png").default
    })`,
  },
  marcianito: {
    height: 100,
    width: 100,
    position: "absolute",
    zIndex: 100,
    bottom: 0,
    right: 0,
  },
  cuadroTexto: {
    marginTop: -10,
    padding: "35px 3px 25px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url(${
      require("../assets/header/Botones/dialogo1.png").default
    })`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  layoutSubmenu: {
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  alegreyaSansSCBold: {
    fontFamily: "AlegreyaSansSC-Bold",
    fontSize: "22px",
    color: "#002B49",
    width: "100%",
    height: "28px",
    margin: "7px auto 0",
    "&:hover": {
      color: "#E4323F",
    },
  },
}));
const BotonMenuCollapse = ({ clickWine }) => {
  const matches = useMediaQuery("( min-width:960px)");
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [checked, setChecked] = useState(false);

  return (
    <React.Fragment>
      <div
        style={{ position: "relative" }}
        onClick={matches === true ? () => history.push("Our-Wines") : null}
        onMouseEnter={() => setChecked(true)}
        onMouseLeave={() => setChecked(false)}
      >
        <BotonMenu
          handleClose={clickWine}
          link={null}
          tipo={2}
          inclinacion={3}
          fixedHover={checked}
        >
          {t("menu.ourWines1")}
          <Img src={copa_vino} />
          {t("menu.ourWines2")}
        </BotonMenu>
        <Collapse in={checked}>
          <div className={classes.cuadroTexto}>
            <div className={classes.layoutSubmenu}>
              <Button
                className={classes.alegreyaSansSCBold}
                onClick={() => {
                  clickWine("encuentroSecano");
                  history.push("/Encuentro-Secano");
                }}
              >
                encuentro secano
              </Button>
              <Button
                className={classes.alegreyaSansSCBold}
                onClick={() => {
                  clickWine("divino");
                  history.push("/Divino");
                }}
              >
                divino
              </Button>
              <Button
                className={classes.alegreyaSansSCBold}
                onClick={() => {
                  clickWine("octavoPasajero");
                  history.push("/Octavo-Pasajero");
                }}
              >
                octavo pasajero
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    </React.Fragment>
  );
};
const Header = (props) => {
  const matches = useMediaQuery("( min-width:960px)");
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  // const [checked, setChecked] = useState(false);

  const toggleDrawer = (event) => {
    if (openDrawer === false) {
      setOpenDrawer(!openDrawer);
      marciano();
    } else {
      marciano();
      handleClose();
    }
  };
  // const handleChange = () => {
  //   setChecked((prev) => !prev);
  // };
  const marciano = () => {
    setTimeout(() => {
      setOpenMenu(!openMenu);
    }, 500);
  };
  const handleClose = () => {
    setOpenMenu(false);
    // setChecked(false);
    setTimeout(function () {
      setOpenDrawer(!openDrawer);
    }, 750);
  };
  const clickWine = (ide) => {
    // handleChange();
    handleClose();
    setTimeout(() => {
      const anchor = document.querySelector(`#${ide}`);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 1000);
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoOvni} onClick={() => history.push("/")} />
          <Button className={classes.buttonClose} onClick={toggleDrawer}>
            <MenuIcon />
          </Button>
        </Toolbar>
      </AppBar>

      <CustomDrawer
        anchor={matches === true ? "right" : "top"}
        open={openDrawer}
        onClose={handleClose}
      >
        <FondoAnimado
          fullHeight={true}
          fondo={require("../assets/header/fondosimple.png").default}
          animacion={
            require("../assets/header/img_fondo_letras_vertical.png").default
          }
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <BotonMenu
              handleClose={handleClose}
              link="/The-Story"
              tipo={0}
              inclinacion={3}
            >
              <Img src={manito_izq} />
              {t("menu.theStory")}
              <Img src={manito_der} />
            </BotonMenu>

            <BotonMenu
              handleClose={handleClose}
              link="/Ovni-Soul"
              tipo={1}
              inclinacion={-5}
            >
              <Img src={marcianito} />
              {t("menu.ovniSoul")}
            </BotonMenu>
            <BotonMenuCollapse clickWine={clickWine} />

            <BotonMenu
              handleClose={handleClose}
              link="/Being-Here"
              tipo={3}
              inclinacion={-2}
            >
              <Img src={chupetin} />
              {t("menu.beingHere")}
            </BotonMenu>

            <BotonShop handleClose={handleClose} />
          </Grid>

          {openMenu && (
            <Grid container className={classes.marcianito}>
              <BotonWhatsapp />
            </Grid>
          )}
        </FondoAnimado>
      </CustomDrawer>
      <NumberContext.Provider
        value={{
          toggleDrawer,
        }}
      >
        <main style={{ paddingTop: 70 }}>{props.children}</main>
      </NumberContext.Provider>
    </React.Fragment>
  );
};

export default Header;
