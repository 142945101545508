import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  // Route,
  // Redirect
} from "react-router-dom";
import { useCookies } from "react-cookie";
import loadable from "@loadable/component";
import "./App.css";

import RouteWithHeader from "./common/RouteWithHeader";
import Home from "./views/Home/Home";
const TheStory = loadable(() => import("./views/Home/TheStory"));
const OvniSoul = loadable(() => import("./views/Home/OvniSoul/OvniSoul"));
const OurWines = loadable(() => import("./views/Home/OurWines/OurWines"));
const Cinthia = loadable(() => import("./views/Home/TheStory/Cinthia"));
const Gonzalo = loadable(() => import("./views/Home/TheStory/Gonzalo"));
const BeingHere = loadable(() => import("./views/Home/BeingHere"));
const Shop = loadable(() => import("./views/Home/Shop"));
const Legal = loadable(() => import("./views/Home/Legal"));
const DivinoHeader = loadable(() => import("./views/Home/OurWines/DivinoHeader"))
const OctavoHeader = loadable(() => import("./views/Home/OurWines/OctavoHeader"))
const EncuentroHeader = loadable(() => import("./views/Home/OurWines/EncuentroHeader"))
function App() {
  const [cookies, setCookie] = useCookies(["legacy"]);
  const showLegacy = () => {
    let legacy = cookies.legacy;
    if (Boolean(legacy === "true")) {
      return;
    } else {
      setCookie("legacy", "true", { maxAge: 30 * 60 });
      return <Redirect to="/legal" />;
    }
  };
  return (
    <Router>
      {showLegacy()}
      <Switch>
        <Route exact path="/legal" component={Legal} />
        <RouteWithHeader exact path="/" component={Home} />
        <RouteWithHeader exact path="/The-Story" component={TheStory} />
        <RouteWithHeader exact path="/Ovni-Soul" component={OvniSoul} />
        <RouteWithHeader exact path="/Our-Wines" component={OurWines} />
        <RouteWithHeader exact path="/Encuentro-Secano" component={EncuentroHeader} />
        <RouteWithHeader exact path="/Divino" component={DivinoHeader}/>
        <RouteWithHeader exact path="/Octavo-Pasajero" component={OctavoHeader} />
        <RouteWithHeader exact path="/Being-Here" component={BeingHere} />
        <RouteWithHeader exact path="/Shop" component={Shop} />
        <RouteWithHeader exact path="/TheStory/Cinthia" component={Cinthia} />
        <RouteWithHeader exact path="/TheStory/Gonzalo" component={Gonzalo} />
      </Switch>
    </Router>
  );
}

export default App;
