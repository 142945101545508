import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const BotonWhatsapp = () => {
  const useStyles = makeStyles((theme) => ({
    contenedor: {
      position: "relative",
      zIndex: 100,
    },
    marcianito: {
      cursor: "pointer",
      width: "82px",
      height: "86px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${
        require("../assets/header/marciano.png").default
      })`,
    },
    openText: {
      position: "absolute",
      top: 0,
      left: -220,
      width: "218px",
      height: "52px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "AlegreyaSansSC-Bold",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${
        require("../assets/header/Botones/dialogoAlien.png").default
      })`,
      [theme.breakpoints.down(360)]:{
width: "200px",
height: "50",
left: -200
      },
      [theme.breakpoints.up(960)]: {
        top: -35,
        width: "250px",
        height: "103px",
        backgroundImage: `url(${
          require("../assets/header/Botones/dialogoAlienx2.png").default
        })`,
      },
    },
    text: {
      position: "relative",
      left: -20,
      fontFamily: "AlegreyaSansSC-Bold",
      fontSize: "23px",
      color: "#002B49",
      lineHeight: "27px",
      textAlign: "center",
    },
    bold: {
      fontFamily: "AlegreyaSansSC-Bold",
      fontSize: "23px",
      color: "#002B49",
      lineHeight: "27px",
      textAlign: "center",
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation();
  const [overBoton, setOverBoton] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const redirigirWSP = () => {
    const a = document.createElement("a");
    a.setAttribute(
      "href",
      "https://api.whatsapp.com/send?phone=56988191629&text=Hola%20%F0%9F%91%8B%F0%9F%8F%BB!"
    );
    a.setAttribute("target", "_blank");
    a.setAttribute("id", "iphone");
    document.body.appendChild(a);
    document.querySelector("#iphone").click();
    document.body.removeChild(a);
  };
  useEffect(() => {
    setOverBoton(true);
  });
  const handleClick = () => {
    if (matches === true) {
      setOverBoton(true);
      redirigirWSP();
    } else {
      setOverBoton(true);
      setTimeout(() => {
        setOverBoton(false);
        redirigirWSP();
      }, 2000);
    }
  };
  return (
    <div className={classes.contenedor}>
      {overBoton && (
        <div className={classes.openText}>
          <Typography className={classes.text}>{t("menu.msgAlien")}</Typography>
        </div>
      )}
      <div
        className={classes.marcianito}
        onClick={handleClick}
        onMouseEnter={() => setOverBoton(true)}
        onMouseLeave={() => setOverBoton(false)}
      ></div>
    </div>
  );
};

export default BotonWhatsapp;
