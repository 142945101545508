import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FondoAnimado from "../../components/FondoAnimado";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { NumberContext } from "../../common/Header";
import { Grid } from "@material-ui/core";
import botella_1 from "../../assets/home/botella_1.png";
import botella_2 from "../../assets/home/botella_2.png";
import botella_3 from "../../assets/home/botella_3.png";

const useStyles = makeStyles((theme) => ({
  containerVideoOvni: {
    margin: "auto",
    position: "relative",
    width: "100%",
    marginTop: -75,
    minHeight: "calc(100vh + 10px)",
    zIndex: 0,
  },
  containerNave: {
    height: 140,
    textAlign: "center",
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: 140,
    },
    [theme.breakpoints.up("md")]: {
      height: 165,
    },
    [theme.breakpoints.up("md")]: {
      height: 240,
    },
  },
  nave: {
    top: 0,
    left: 0,
    right: 0,
    margin: "auto",
    marginBottom: "30px",
    width: "100%",
    maxWidth: 200,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 265,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 350,
      // maxHeight:"100vh"
    },
  },
  videoOvni: {
    zIndex: 2,
    margin: "auto",
    width: "100%",
    maxWidth: 150,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 260,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 280,
      // maxHeight:"100vh"
    },
  },
  textoOvni: {
    zIndex: 2,
    margin: "auto",
    width: 160,
    // height: 60,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    // maxWidth: 190,
    [theme.breakpoints.up(360)]: {
      width: 190,
    },
    [theme.breakpoints.up("sm")]: {
      width: 210,
    },
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
  },
  botellas: {
    position: "absolute",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: 90,
    height: 150,
    [theme.breakpoints.up("sm")]: {
      width: 110,
      height: 190,
    },
    [theme.breakpoints.up("md")]: {
      width: 140,
      height: 230,
    },
  },
}));

const Botella = ({ imagen, index, top, left, tipo }) => {
  const useStylesBotella = makeStyles((theme) => ({
    container: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      top,
      left,
      [theme.breakpoints.up("sm")]: {
        left: `calc(${left} + 7%)`,
      },
      [theme.breakpoints.up("md")]: {
        top: tipo === 0 ? `calc(${top} - 3%)` : `calc(${top} + 3%)`,
      },
      [theme.breakpoints.up("lg")]: {
        top: tipo === 0 ? `calc(${top} - 10%)` : `calc(${top} + 10%)`,
      },
    },
    containerBotella: {
      display: "flex",
      justifyContent: "center",
      height: 90,
      [theme.breakpoints.up(400)]: {
        height: 120,
      },
      [theme.breakpoints.up("sm")]: {
        height: 120,
      },
      [theme.breakpoints.up("lg")]: {
        height: 200,
      },
    },
    botella: {
      height: 90,
      [theme.breakpoints.up(400)]: {
        height: 120,
      },
      [theme.breakpoints.up("sm")]: {
        height: 120,
      },
      [theme.breakpoints.up("lg")]: {
        height: 200,
      },
    },
  }));
  const classes = useStylesBotella();
  return (
    <div className={classes.container}>
      <div className={classes.containerBotella}>
        <div className="animated leviteBottle">
          <img
            alt={`botella-${index}`}
            src={imagen}
            className={classes.botella}
          />
        </div>
      </div>
      <img
        alt="sombra"
        src={require("../../assets/home/sombra.png").default}
        style={{ marginTop: 15 }}
      />
    </div>
  );
};
const Home = () => {
  const classes = useStyles();
  const { toggleDrawer } = useContext(NumberContext);
  const matches = useMediaQuery("(min-width:600px)");
  const matchesLg = useMediaQuery("(min-width:1280px)");

  useEffect(() => {
    window.addEventListener("scroll", checkScrollDirection);
    return () => {
      window.removeEventListener("scroll", checkScrollDirection);
    };
  }, []);
  function checkScrollDirection(event) {
    if (checkScrollDirectionIsUp(event)) {
      // toggleDrawer(); no va
    } else {
      toggleDrawer();
      window.scrollTo(0, 0);
    }
  }
  function checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }
  return (
    <div className={classes.containerVideoOvni}>
      <FondoAnimado
        fullHeight={true}
        fondo={require("../../assets/header/fondosimple.png").default}
        animacion={
          matches === true
            ? require("../../assets/header/fondo_letras.png").default
            : require("../../assets/header/img_fondo_letras_vertical.png")
                .default
        }
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            <Botella imagen={botella_1} top={"20%"} left={"2%"} tipo={0} />
            <Botella imagen={botella_2} top={"12%"} left={"38%"} tipo={0} />
            <Botella imagen={botella_3} top={"20%"} left={"70%"} tipo={0} />
            <Botella imagen={botella_1} top={"65%"} left={"70%"} tipo={1} />
            <Botella imagen={botella_2} top={"75%"} left={"38%"} tipo={1} />
            <Botella imagen={botella_3} top={"65%"} left={"2%"} tipo={1} />

            {matchesLg && (
              <Botella imagen={botella_2} top={"32%"} left={"2%"} tipo={1} />
            )}
            {matchesLg && (
              <Botella imagen={botella_2} top={"32%"} left={"70%"} tipo={1} />
            )}
          </div>
        </div>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          style={{ marginTop: -100 }}
        >
          <div className={classes.containerNave}>
            {/* <div className="animated leviteBottle"> */}
            <img
              className={classes.nave}
              alt={"video ovni"}
              src={require("../../assets/home/nave_animada.gif").default}
            />
            {/* <picture>
               <source srcSet={require("../../assets/home/nave.webp").default} type="img/webp"/>
                <source srcSet={require("../../assets/home/nave.png").default} type="img/png"/>
                <img
                className={classes.nave}
                alt={"video ovni"}
                src={require("../../assets/home/nave.png").default}
              />
              </picture> */}
            {/* </div> */}
          </div>

          <img
            className={classes.videoOvni}
            alt={"video ovni"}
            src={require("../../assets/home/ovni_vino.gif").default}
          />
          <img
            className={classes.textoOvni}
            alt={"video ovni"}
            src={require("../../assets/home/ovni_texto.png").default}
          />
        </Grid>
      </FondoAnimado>
    </div>
  );
};

export default Home;
